/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

html, body {
    height: 100%;
}

html {
    font-family: "Trebuchet MS", system-ui;
}

body {
    background-color: #f4f4f4;
}

/* ELEMENTY STRONY DLA UŻYTKOWNIKA NIEZALOGOWANEGO */

.public_main {
    width: 95%;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding-top: 8%;
}

.public_main h1 {
    font-size: x-large;
}

.public_main .logo {
    max-width: 250px;
}

.public_main form {
    text-align: left;
}

/* NAGŁÓWEK */

.navbar-brand {
    padding: 15px;
}

.navbar-brand img {
    width: 200px;
}

.navbar-text {
    text-align: center;
}

/* TREŚĆ GŁÓWNA */

main h1, h2, h3 {
    text-align: center;
}

.react-calendar {
    margin: auto;
}

.pointer:hover {
    cursor: pointer;
}

/* ELEMENTY WSPÓLNE */

.center {
    text-align: center;
}

.trakcja_img {
    width: 40px;
}

.table_centered {
    margin: auto;
}

.table_centered td, .table_centered th {
    text-align: center;
}

/* DZIEŃ W KALENDARZU */

.koordynator {
    text-align: left;
}

.gdp {
    font-weight: bold;
}

.gdp_uwagi {
    font-style: italic;
}

/* NUMERY TELEFONÓW – INFORMACJE DODATKOWE */
.telefony li::marker {
    content: "📞";
}

.telefony a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}

.telefony a:hover {
    text-decoration: underline;
}

.telefony a:visited {
    color: black;
}

/* ZDJĘCIA ZESPOŁU INSTRUKTORSKIEGO I KOORDYNATORSKIEGO – INFORMACJE DODATKOWE */
.osoby {
    text-align: center;
}

.osoby figure{
    margin: 0 1rem 0.5rem;
}

.osoby figure img {
    max-height: 300px;
}

.osoby figcaption {
    text-align: center;
}

.osoby figcaption span {
    font-weight: bold;
}
